<!--  -->
<template>
    <div class="curriculum">
        <entrance></entrance>
        <div class="iput flex-align-center">我的字帖</div>
        <div class="main flex-layout">
            <div class="flex-layout">
                <div class="switchType">
                    <div class="flex-align-center" :class="{ btncolor: switchIndex == index }"
                        v-for="(item, index) in switchTypeList" :key="index" @click="switchclick(index)">
                        {{ item }}
                    </div>
                </div>
                <!-- 同步教材 -->
                <div class="content" v-if="switchIndex == 0">
                    <el-button-group>
                        <el-button :class="{ 'activate': btnType == 0 }" @click="changeBtnType(0)"
                            icon="el-icon-download">下载字帖</el-button>
                        <el-button :class="{ 'activate': btnType == 1 }" @click="changeBtnType(1)"
                            icon="el-icon-refresh">生成AI测评字帖</el-button>
                    </el-button-group>
                    <template v-if="btnType == 0">
                        <div class="down-wrap flex-layout">
                            <div class="form-box flex-column">
                                <el-form ref="downloadFrom" :model="downloadFrom" label-position="right" label-width="80px"
                                    :rules="downloadRules">
                                    <el-form-item label="字帖类型" prop="type">
                                        <el-select v-model="downloadFrom.type" placeholder="请选字帖类型"
                                            :popper-append-to-body="false" @change="typeChange">
                                            <el-option :label="item.label" :value="item.value"
                                                v-for="(item, index) in copyBookType" :key="index"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="选择年级" prop="grade" v-if="downloadFrom.type == 11">
                                        <el-select v-model="downloadFrom.grade" placeholder="请选择年级"
                                            :popper-append-to-body="false" @change="gradeChange">
                                            <el-option :label="item.name + '-' + item.term" :value="item.id"
                                                v-for="(item, index) in gradeList" :key="index">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="选择单元" v-if="downloadFrom.type == 11">
                                        <el-select v-model="downloadFrom.cell" placeholder="请选择单元"
                                            :popper-append-to-body="false" @change="cellChange">
                                            <el-option :label="item.name" :value="item.id" v-for="(item, index) in cellList"
                                                :key="index"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="选择级别" prop="level" v-if="downloadFrom.type == 12">
                                        <el-select v-model="downloadFrom.level" placeholder="请选字帖类型"
                                            :popper-append-to-body="false" @change="levelChange">
                                            <el-option :label="item.brushName" :value="item.level"
                                                v-for="(item, index) in levelList" :key="index"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="选择课节" :prop="downloadFrom.type == 11 ? '' : 'course'"
                                        v-if="downloadFrom.type !== 13">
                                        <el-select v-model="downloadFrom.course" placeholder="请选择课节"
                                            :popper-append-to-body="false">
                                            <el-option :label="item.name" :value="item.id"
                                                v-for="(item, index) in courseList" :key="index"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-form>
                            </div>
                            <div class="img-box flex-column-center">
                                <div class="copybook-img2 flex-layout" v-if="downloadFrom.type == 13">
                                    <img src="@/assets/copybook/kongbi.jpg" alt="">
                                </div>
                                <div class="copybook-img flex-layout" v-else
                                    :style="{ backgroundImage: backgroundImageGrade[downloadFrom.grade] }">
                                    <div class="grade flex-align-around"
                                        v-if="gradeList.length > 0 && downloadFrom.grade && downloadFrom.type == 11">
                                        {{ gradeList.find(item => item.id == downloadFrom.grade).name }}{{
                                            gradeList.find(item => item.id == downloadFrom.grade).term.replace("下学期", "下册") }}
                                    </div>
                                    <div class="grade flex-align-around"
                                        v-if="levelList.length > 0 && downloadFrom.level && downloadFrom.type == 12">
                                        {{ levelList.find(item => item.level == downloadFrom.level).brushName }}
                                    </div>
                                    <div class="type">{{ copyBookType.find(item => item.value == downloadFrom.type).label }}
                                    </div>
                                </div>
                                <span class="download" @click="createCopyBook">下载</span>
                            </div>
                        </div>
                    </template>
                    <template v-if="btnType == 1">
                        <div class="down-wrap flex-layout">
                            <div class="form-box flex-column">
                                <el-form ref="downloadFrom" :model="downloadFrom" label-position="right" label-width="80px"
                                    :rules="downloadRules">
                                    <el-form-item label="字帖类型" prop="type">
                                        <el-select v-model="downloadFrom.type" placeholder="请选字帖类型"
                                            :popper-append-to-body="false" @change="typeChange">
                                            <el-option :label="item.label" :value="item.value"
                                                v-for="(item, index) in copyBookType.slice(0,2)" :key="index"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="选择年级" prop="grade" v-if="downloadFrom.type == 11">
                                        <el-select v-model="downloadFrom.grade" placeholder="请选择年级"
                                            :popper-append-to-body="false" @change="gradeChange">
                                            <el-option :label="item.name + '-' + item.term" :value="item.id"
                                                v-for="(item, index) in gradeList" :key="index"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="选择单元" prop="cell" v-if="downloadFrom.type == 11">
                                        <el-select v-model="downloadFrom.cell" placeholder="请选择单元"
                                            :popper-append-to-body="false" @change="cellChange">
                                            <el-option :label="item.name" :value="item.id" v-for="(item, index) in cellList"
                                                :key="index"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="选择级别" prop="level" v-if="downloadFrom.type == 12">
                                        <el-select v-model="downloadFrom.level" placeholder="请选字帖类型"
                                            :popper-append-to-body="false" @change="levelChange">
                                            <el-option :label="item.brushName" :value="item.level"
                                                v-for="(item, index) in levelList" :key="index"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="选择课节" prop="course">
                                        <el-select v-model="downloadFrom.course" placeholder="请选择课节"
                                            :popper-append-to-body="false">
                                            <el-option :label="item.name" :value="item.id"
                                                v-for="(item, index) in courseList" :key="index"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="绑定学生">
                                        <div class="bindStu flex-align">
                                            <el-select v-model="downloadFrom.class" placeholder="请选择班级"
                                                @change="changeClass" :popper-append-to-body="false">
                                                <el-option v-for="(item, index) in classList" :key="index"
                                                    :label="item.name" :value="item.id">
                                                </el-option>
                                            </el-select>
                                            <span v-if="downloadFrom.class == -1">(已选{{ allSize }}人)</span>
                                            <span v-else>(已选{{ bindStuList.length || 0 }}人)</span>
                                            <div v-if="downloadFrom.class !== -1" class="selectStu flex-align"
                                                @click="chooseStu(bindStu)">
                                                <img src="@/assets/copybook/add4.png" alt="">
                                                选择学生
                                            </div>
                                        </div>
                                    </el-form-item>
                                </el-form>
                                <div class="create" @click="createCopyBook">生成字帖</div>
                            </div>
                            <div class="img-box flex-column-center">
                                <div class="copybook-img flex-layout"
                                    :style="{ backgroundImage: backgroundImageGrade[downloadFrom.grade] }">
                                    <div class="courseName"
                                        v-if="downloadFrom.type == 11 && downloadFrom.cell > -1 && downloadFrom.course == -1">
                                        {{ cellList.find(item => item.id == downloadFrom.cell).name }}
                                    </div>
                                    <div class="courseName" v-if="downloadFrom.course !== -1 && downloadFrom.type == 12">
                                        {{ courseList.find(item => item.id == downloadFrom.course).name }}
                                    </div>
                                    <div class="courseName" v-if="downloadFrom.course !== -1 && downloadFrom.type == 11">
                                        <span class="index">{{ courseList.find(item => item.id ==
                                            downloadFrom.course).name.split(" ")[0] }}</span>
                                        {{ courseList.find(item => item.id == downloadFrom.course).name.split(" ")[1] }}
                                    </div>
                                    <div class="grade flex-align-around"
                                        v-if="gradeList.length > 0 && downloadFrom.grade && downloadFrom.type == 11">
                                        {{ gradeList.find(item => item.id == downloadFrom.grade).name }}{{
                                            gradeList.find(item => item.id == downloadFrom.grade).term.replace("下学期", "下册") }}
                                    </div>
                                    <div class="grade flex-align-around"
                                        v-if="levelList.length > 0 && downloadFrom.level && downloadFrom.type == 12">
                                        {{ levelList.find(item => item.level == downloadFrom.level).brushName }}
                                    </div>
                                    <div class="type">{{ copyBookType.find(item => item.value == downloadFrom.type).label }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
                <!-- 测评卷 -->
                <div class="content" v-if="switchIndex == 1">
                    <el-button-group>
                        <el-button :class="{ 'activate': btnType == 0 }" @click="changeBtnType(0)"
                            icon="el-icon-download">下载试卷</el-button>
                        <el-button :class="{ 'activate': btnType == 1 }" @click="changeBtnType(1)"
                            icon="el-icon-refresh">生成AI测评试卷</el-button>
                    </el-button-group>
                    <div class="down-wrap flex-layout">
                        <div class="form-box flex-column">
                            <el-form ref="downloadFrom" :model="testForm" label-position="right" label-width="80px"
                                :rules="testFormRules">
                                <el-form-item label="试卷类型" prop="type">
                                    <el-select v-model="testForm.type" placeholder="请选试卷类型" :popper-append-to-body="false"
                                        @change="typeChange">
                                        <el-option :label="item.label" :value="item.value"
                                            v-for="(item, index) in testTypeList" :key="index"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="选择年级" prop="grade">
                                    <el-select v-model="testForm.grade" placeholder="请选择年级" :popper-append-to-body="false"
                                        @change="gradeChange">
                                        <el-option :label="testForm.type > 2 ? item.name + '-' + item.term : item.name"
                                            :value="item.id" v-for="(item, index) in gradeList" :key="index"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="选择试卷" prop="testId" v-if="testForm.type > 2">
                                    <el-select v-model="testForm.testId" placeholder="请选试卷" :popper-append-to-body="false"
                                        @change="typeChange">
                                        <el-option :label="item.name" :value="item.id" v-for="(item, index) in testList"
                                            :key="index"></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="绑定学生" v-if="btnType == 1">
                                    <div class="bindStu flex-align">
                                        <el-select v-model="downloadFrom.class" placeholder="请选择班级" @change="changeClass"
                                            :popper-append-to-body="false">
                                            <el-option v-for="(item, index) in classList" :key="index" :label="item.name"
                                                :value="item.id">
                                            </el-option>
                                        </el-select>
                                        <span v-if="downloadFrom.class == -1">(已选{{ allSize }}人)</span>
                                        <span v-else>(已选{{ bindStuList.length || 0 }}人)</span>
                                        <div v-if="downloadFrom.class !== -1" class="selectStu flex-align"
                                            @click="chooseStu(bindStu)">
                                            <img src="@/assets/copybook/add4.png" alt="">
                                            选择学生
                                        </div>
                                    </div>
                                </el-form-item>
                            </el-form>
                            <div class="create" @click="createCopyBook" v-if="btnType == 1">
                                生成测评卷</div>
                        </div>
                        <div class="img-box flex-column-center">
                            <div class="copybook-img flex-layout">
                                <img src="@/assets/copybook/test_before.jpg" alt=""
                                    v-if="btnType == 0 && testForm.type == 1">
                                <img src="@/assets/copybook/test_before_ai.jpg" alt=""
                                    v-if="btnType == 1 && testForm.type == 1">
                                <img src="@/assets/copybook/test_after.jpg" alt=""
                                    v-if="btnType == 0 && testForm.type == 2">
                                <img src="@/assets/copybook/test_after_ai.jpg" alt=""
                                    v-if="btnType == 1 && testForm.type == 2">
                                <img src="@/assets/copybook/test_month.jpg" alt=""
                                    v-if="btnType == 0 && testForm.type == 4">
                                <img src="@/assets/copybook/test_month_ai.jpg" alt=""
                                    v-if="btnType == 1 && testForm.type == 4">
                                <img src="@/assets/copybook/test_week.jpg" alt="" v-if="btnType == 0 && testForm.type == 5">
                                <img src="@/assets/copybook/test_week_ai.jpg" alt=""
                                    v-if="btnType == 1 && testForm.type == 5">
                            </div>
                            <span class="download" @click="createCopyBook" v-if="btnType == 0">下载</span>
                        </div>
                    </div>
                </div>
                <!-- 自定义字帖 -->
                <!-- <div class="content3" v-if="switchIndex == 2">
                    <div class="empty-box flex-column-center">
                        <el-empty description="暂无内容"></el-empty>
                    </div>
                    <div class="top flex-align">
                        <div class="create flex-align-around" @click="createWord">
                            <div class="flex-align-around">
                                <img src="@/assets/copybook/add2.png" alt="">
                                新建字帖
                            </div>
                        </div>
                    </div>
                    <div class="templateWrap flex-align-around">
                        <div class="type_word flex-column-center">
                            <img src="@/assets/copybook/type4.jpg" alt="" :class="{ 'imgActive': modelIndex == 0 }"
                                @click="modelIndex = 0">
                            <div class="create2 flex-align-around">
                                <div class="flex-align-around">
                                    模板一
                                </div>
                            </div>
                        </div>
                        <div class="type_text flex-column-center">
                            <img src="@/assets/copybook/type5.jpg" alt="" :class="{ 'imgActive': modelIndex == 1 }"
                                @click="modelIndex = 1">
                            <div class="create2 flex-align-around">
                                <div class="flex-align-around">
                                    模板二
                                </div>
                            </div>
                        </div>
                        <div class="type_text flex-column-center">
                            <img src="@/assets/copybook/type6.jpg" alt="" :class="{ 'imgActive': modelIndex == 2 }"
                                @click="modelIndex = 2">
                            <div class="create2 flex-align-around">
                                <div class="flex-align-around">
                                    模板三
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <!-- 生成记录 -->
                <div class="content2" v-if="switchIndex == 2">
                    <div class="top flex-align">
                        <el-input class="myInput" placeholder="输入关键词搜索" suffix-icon="el-icon-search" @change="search"
                            v-model="searchVal">
                        </el-input>
                    </div>
                    <div class="itemWrap flex-align">
                        <template v-if="copyList.length > 0">
                            <div class="myItem" v-for="(item, index) in  copyList " :key="index">
                                <div class="mark">AI测评</div>
                                <div class="item flex-align" :style="{ backgroundImage: backgroundImage[item.type] }">
                                    <div class="loading" v-if="item.status == 102">字帖生成中...</div>
                                    <div class="menu flex-between">
                                        <div class="edit flex-align-center" :class="{ 'active': item.status == 2 }"
                                            @click="preview(item.status, item.file)">
                                            预览</div>
                                        <div class="download flex-align-center" :class="{ 'active': item.status == 2 }"
                                            @click="preview(item.status, item.fileDownloadUrl)">下载</div>
                                    </div>
                                    <div class="item-info flex-align">
                                        <span class="item-title" v-if="item.type == 11">
                                            <span class="index" v-if="JSON.parse(item.genJson).hasOwnProperty('课程')">{{
                                                item.name.split(" ")[0] }}</span>
                                            <span v-else>{{ item.name.split(" ")[0] }}</span>
                                            {{ item.name.split(" ")[1] }}
                                        </span>
                                        <span class="item-title" v-else>{{ item.name }}</span>
                                        <span class="item-grade" v-if="item.type <= 5">{{ mygradelist[item.grade]
                                        }}</span>
                                        <span class="item-type">{{ testTypes[item.type] }}</span>
                                    </div>
                                </div>
                                <span class="name-box flex-align-between">
                                    <span class="name wes" :title="item.name">{{ item.name }}</span>
                                    <span class="class">{{ item.className }}</span>
                                </span>
                                <span class="time">{{ transformTimestamp(item.createTime) }}</span>
                            </div>
                        </template>
                        <div class="empty flex-column-center" v-else>
                            <img src="@/assets/empty.png" />
                            <span>没有查到相关字帖~</span>
                        </div>
                    </div>
                    <div class="page flex-align-center">
                        <el-pagination key="myCreate" background layout="prev, pager, next,jumper" :page-size="4"
                            :total="total" @current-change="myCreatChange">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
        <!-- 选择字帖模板弹窗 -->
        <el-dialog :visible='dialogVisible' :before-close="handleClose" center title="新建字帖">
            <div class="templateWrap flex-align-around">
                <div class="type_word flex-column-center">
                    <img src="@/assets/copybook/type1.png" alt="">
                    <div class="create2 flex-align-around" @click="goCreate(1)">
                        <div class="flex-align-around">
                            创建写字单字帖
                        </div>
                    </div>
                </div>
                <div class="type_text flex-column-center">
                    <img src="@/assets/copybook/type2.png" alt="">
                    <div class="create2 flex-align-around" @click="goCreate(2)">
                        <div class="flex-align-around">
                            创建篇章字帖
                        </div>
                    </div>
                </div>
                <div class="type_text flex-column-center">
                    <img src="@/assets/copybook/type3.png" alt="">
                    <div class="create2 flex-align-around" @click="goCreate(4)">
                        <div class="flex-align-around">
                            创建生字字帖
                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>
        <!-- 创建副本在编辑弹窗 -->
        <el-dialog custom-class="myDialog" top='30vh' :visible='dialogVisible2' :before-close="handleClose2" center
            title="创建副本再编辑">
            <div class="tipWrap flex-column-center">
                <span>这是一个单元字帖模板，请创建副本并编辑</span>
                <div class="btns flex-align">
                    <div class="cancel" @click="handleClose2">取消</div>
                    <div class="make" @click="editDefault">创建副本</div>
                </div>
            </div>
        </el-dialog>
        <!-- 绑定学生弹窗 -->
        <el-dialog custom-class="bindStuDialog" :visible='dialogVisibleBindStu' :before-close="handleClose" center
            title="绑定学生">
            <div class="bindWrap flex-align-between">
                <span>{{ schoolName }}-{{ className }}</span>
                <el-input placeholder="请输入学生姓名" v-model="searchName" @input="searchStu" suffix-icon="el-icon-search">
                </el-input>
            </div>
            <el-table :data="tableData" stripe style="width: 100%" @selection-change="handleSelectionChange">
                <el-table-column width="150" align="center">
                    <template slot="header" slot-scope="scope">
                        <el-checkbox v-model="allSelect" @change="changeAll">全选</el-checkbox>
                    </template>
                    <template slot-scope="scope">
                        <el-checkbox v-model="scope.row.select"
                            @change="selectStu(scope.row.id, scope.$index, scope.row.select)">
                        </el-checkbox>
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="姓名" width="150" align="center">
                </el-table-column>
                <el-table-column prop="className" label="班级" align="center">
                </el-table-column>
            </el-table>
            <div class="pageWrap flex-align-around">
                <el-pagination background layout="prev, pager, next,total" :page-size='5' :total="total"
                    @current-change="handleCurrentChange">
                </el-pagination>
            </div>
            <div class="confirm" @click="handleClose">
                确定
            </div>
        </el-dialog>
        <!-- 生成字帖弹窗 -->
        <el-dialog custom-class="myDialog" :visible='dialogVisibleCreate' :before-close="handleClose" center title="生成字帖">
            <div class="tipWrap flex-column-center">
                <span>正在生成字帖，这个过程需要几分钟...</span>
                <span>您可先进行其他操作,字帖生成后会保存在</span>
                <span>"生成记录"中</span>
                <div class="check" @click="check">去查看</div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import entrance from "../../Common_components/head/entrance";
import subscribe from "../../Common_components/Popup/subscribe.vue";
import {
    mapState,
    mapMutations
} from "vuex";
export default {
    data() {
        return {
            role: sessionStorage.getItem('role'),
            switchTypeList: ["同步教材", "测评卷", "生成记录"],
            switchIndex: 0,
            searchVal: '',
            dialogVisible: false,
            dialogVisible2: false,
            classList: [],
            currentIndex: -1,
            currentType: 0,
            currentGradeId: '',
            page: 1,
            pageSize: 4,
            copyList: [],
            total: null,
            modelId: null,
            btnType: 0,
            modelIndex: 0,
            downloadFrom: {
                type: 11,
                grade: '',
                cell: -1,
                course: -1,
                level: '',
                class: ''
            },
            downloadRules: {
                type: [
                    { required: true, message: '请选择字体类型', trigger: 'change' }
                ],
                grade: [
                    { required: true, message: '请选择年级', trigger: 'change' }
                ],
                level: [
                    { required: true, message: '请选择级别', trigger: 'change' }
                ],
                course: [
                    { required: true, message: '请选择课节', trigger: 'change' }
                ],
                cell: [
                    { required: true, message: '请选择单元', trigger: 'change' }
                ],
            },
            testFormRules: {
                type: [
                    { required: true, message: '请选择字体类型', trigger: 'change' }
                ],
                grade: [
                    { required: true, message: '请选择年级', trigger: 'change' }
                ],
                testId: [
                    { required: true, message: '请选择试卷', trigger: 'change' }
                ]
            },
            searchName: '',
            bindStu: '',
            dialogVisibleBindStu: false,
            schoolName: '',
            schoolId: '',
            className: '',
            originData: [], // 学生列表原始数据
            tableData: [], // 学生列表分页数据
            dialogVisibleCreate: false,
            copyBookType: [
                { label: '同步练字本', value: 11 },
                { label: '硬笔体系字帖', value: 12 },
                { label: '控笔练习', value: 13 },
            ],
            gradeList: [],
            cellList: [
                {
                    name: '全部',
                    id: -1
                }
            ],
            courseList: [
                {
                    name: '全部',
                    id: -1
                }
            ],
            levelList: [],
            bindStuList: [], //绑定学生数组
            bindStuListCopy: [], // 绑定学生数组备份
            allSelect: true,
            testForm: {
                type: 1,
                grade: '',
                testId: '',
                class: ''
            },
            testTypeList: [
                { label: '前测试卷', value: 1 },
                { label: '后测试卷', value: 2 },
                { label: '月测试卷', value: 4 },
                { label: '周测试卷', value: 5 },
            ],
            testList: [],
            backgroundImage: {
                1: "url(" + require('@/assets/copybook/grade7.jpg') + ")",
                2: "url(" + require('@/assets/copybook/grade7.jpg') + ")",
                4: "url(" + require('@/assets/copybook/grade6.jpg') + ")",
                5: "url(" + require('@/assets/copybook/grade7.jpg') + ")",
                11: "url(" + require('@/assets/copybook/grade1.jpg') + ")",
                12: "url(" + require('@/assets/copybook/grade4.jpg') + ")",
                99: "url(" + require('@/assets/copybook/grade5.jpg') + ")",
            },
            testTypes: {
                1: '前测试卷',
                2: '后测试卷',
                4: '月测试卷',
                5: '周测试卷',
                11: '同步练习本',
                12: '硬笔体系字帖',
                99: '自定义字帖'
            },
            backgroundImageGrade: {
                2: "url(" + require('@/assets/copybook/grade1.jpg') + ")",
                4: "url(" + require('@/assets/copybook/grade2.jpg') + ")",
                6: "url(" + require('@/assets/copybook/grade3.jpg') + ")",
                8: "url(" + require('@/assets/copybook/grade4.jpg') + ")",
                10: "url(" + require('@/assets/copybook/grade5.jpg') + ")",
                12: "url(" + require('@/assets/copybook/grade6.jpg') + ")",
            },
            mygradelist: {
                1: '一年级',
                2: '二年级',
                3: '三年级',
                4: '四年级',
                5: '五年级',
                6: '六年级',
            },
            intervalId: null,
            allSize: 0
        };
    },
    components: {
        entrance,
        subscribe,
    },
    computed: {
    },
    //方法
    methods: {
        ...mapMutations(["hint_data"]),
        switchclick(index) {
            if (index < 2) {
                clearInterval(this.intervalId);
                this.intervalId = null
            }
            this.switchIndex = index;
            this.searchVal = '';
            this.page = 1;
            this.bindStuList = [];
            this.bindStuListCopy = [];
            this.downloadFrom.class = '';
            this.btnType = 0
            switch (index) {
                case 0:
                    // console.log('同步教材');
                    // this.getGradeList();
                    break;
                case 1:
                    // console.log('测评卷');
                    this.getTestList();
                    this.getClassList()
                    break;
                case 2:
                    // console.log('自定义字帖');
                    this.copyBookLogPage()
                    break;
                case 3:
                    // console.log('生成记录');
                    this.copyBookLogPage()
                    break;
            }
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.page = val;
            this.getStuList()
        },
        myCreatChange(val) {
            console.log(`当前页: ${val}`);
            this.page = val;
            this.copyBookLogPage();
        },
        // 新建字帖
        createWord() {
            // this.dialogVisible = true;
            this.$router.push({
                path: "/createWord?type=1",
            })
        },
        goCreate(type) {
            let query = {
                type
            }
            this.$router.push({
                path: "/createWord",
                query
            })
        },
        handleClose() {
            this.dialogVisibleBindStu = false
            this.dialogVisibleCreate = false
        },
        handleClose2() {
            this.dialogVisible2 = false
        },
        //时间转换(js将 “2021-07-06T06:23:57.000+00:00” 转换为年月日时分秒)
        transformTimestamp(timestamp) {
            let a = new Date(timestamp).getTime();
            const date = new Date(a);
            const Y = date.getFullYear() + '-';
            const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
            const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + '  ';
            const h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
            const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());
            // const s = date.getSeconds(); // 秒
            const dateString = Y + M + D + h + m;
            // console.log('dateString', dateString); // > dateString 2021-07-06 14:23
            return dateString;
        },
        // 预览字帖、下载字帖
        preview(status, url) {
            console.log(status);
            if (status == 2) {
                window.open(url);
            }
        },
        // 编辑字帖(字帖模板)
        edit(modelId) {
            this.modelId = modelId;
            this.dialogVisible2 = true
        },
        // 编辑字帖(我创建的)
        editMine(status, modelId) {
            if (status == 4) {
                let query = {
                    modelId,
                    isEdit: true
                }
                this.$router.push({
                    path: "/createWord",
                    query
                })
            }
        },
        // 编辑字帖(字帖模板)
        editDefault() {
            let query = {
                modelId: this.modelId,
                isEdit: true,
                copyType: 'defaultMode'
            }
            this.$router.push({
                path: "/createWord",
                query
            })
        },
        // 搜索字帖
        search(val) {
            console.log(val);
            this.searchVal = val;
            this.page = 1;
            this.copyBookLogPage()
        },
        async createCopyBook(formName) {
            let data = {};
            if (this.switchIndex == 0) {
                if (this.downloadFrom.type == 11) {
                    // 同步字帖
                    data.type = 11,
                        data.gradeId = this.downloadFrom.grade,
                        data.unitId = this.downloadFrom.cell == -1 ? '' : this.downloadFrom.cell,
                        data.periodId = this.downloadFrom.course == -1 ? '' : this.downloadFrom.course,
                        data.userIds = []
                } else if (this.downloadFrom.type == 12) {
                    // 硬笔体系课字帖
                    data.type = 12,
                        data.level = this.downloadFrom.level,
                        data.periodId = this.downloadFrom.course == -1 ? '' : this.downloadFrom.course
                } else if (this.downloadFrom.type == 13) {
                    let data = {
                        path:'copybook/static/2025/KB/4 控笔练习-单色单面胶装.pdf',
                        downloadFileName:'控笔练习.pdf'
                    }
                    let resData = await this.$Api.Upload.getTempUrlDownloadFileName(data);
                    console.log('临时访问链接:', resData);
                    // 控笔练习
                    window.open(resData.data)
                    return
                }
            } else if (this.switchIndex == 1) {
                // 测评卷
                data.type = this.testForm.type,
                    data.examId = this.testForm.testId,
                    data.gradeId = this.testForm.grade
            }

            if (this.btnType == 1) {
                if (this.downloadFrom.class == -1) {
                    data.userIds = [0];
                    data.classId = 0
                } else {
                    if (this.bindStuList.length == 0) {
                        this.$message({
                            message: '请绑定学生',
                            type: 'warning'
                        });
                        return
                    } else {
                        data.userIds = this.bindStuList;
                        data.classId = this.downloadFrom.class
                    }
                }

            }
            let resData = await this.$Api.CopyBook.createCopyBook(data);
            console.log('生成字帖:', resData);
            if (this.btnType == 1) {
                this.dialogVisibleCreate = true
            } else {
                this.$message({
                    message: '生成字帖成功',
                    type: 'success'
                });
                window.open(resData.data.downloadFilePath);
            }

        },
        // 选择学生
        chooseStu(classId) {
            if (!classId) {
                this.$message({
                    message: '请先选择班级',
                    type: 'warning'
                });
                return
            }
            this.searchName = '';
            this.getStuList();
            this.dialogVisibleBindStu = true
        },
        getStuList() {
            // 根据班级id获取学生
            // 根据本地数据originData手动本地分页不走接口
            if (this.searchName) {
                this.tableData = this.originData.filter(item => {
                    return item.name == this.searchName
                });
                this.total = this.tableData.length;
            } else {
                this.tableData = this.originData.slice((this.page - 1) * 5, this.page * 5);
                this.total = this.originData.length
            }
        },
        // 获取用户信息
        async getUserInfo() {
            let resData = await this.$Api.Home.getUserInfo();
            // console.log('resData', resData);
            this.schoolName = resData.data.schoolName;
            this.schoolId = resData.data.schoolId;
        },
        changeClass(val) {
            console.log('选择班级:', val);
            this.bindStu = val;
            let index = this.classList.findIndex(item => {
                return item.id == val
            })
            this.className = this.classList[index].name;
            this.bindStuList = [];
            this.bindStuListCopy = [];
            // 选择班级默认选中该班级全部学生
            if (val == -1) {
                let data = {
                    gradeId: this.switchIndex == 0 ? this.downloadFrom.grade : this.testForm.grade,
                    schoolId: this.schoolId
                }
                this.$Api.CopyBook.getStuCountByGradeId(data)
                    .then(res => {
                        console.log('全年级学员列表', res);
                        this.allSize = res.data;
                        // res.data.map(item => {
                        //     item.className = this.className;
                        //     item.select = true;
                        //     this.bindStuList.push(item.id);
                        //     this.bindStuListCopy.push(item.id);
                        // })
                        // this.originData = res.data;
                        // this.tableData = this.originData.slice(0, 5);
                        // this.total = res
                    })
                    .catch(err => {
                        console.log('获取学员失败--', err)
                    })

            } else {
                let data = {
                    classId: this.bindStu,
                }
                this.$Api.CopyBook.getClassStu(data)
                    .then(res => {
                        console.log('学员列表', res);
                        res.data.map(item => {
                            item.className = this.className;
                            item.select = true;
                            this.bindStuList.push(item.id);
                            this.bindStuListCopy.push(item.id);
                        })
                        this.originData = res.data;
                        this.tableData = this.originData.slice(0, 5);
                        this.total = res.data.length;
                        // console.log('对比结果--', this.bindStuList == this.bindStuListCopy)
                    })
                    .catch(err => {
                        console.log('获取学员失败--', err)
                    })
            }

        },
        searchStu(name) {
            if (name.trim()) {
                this.getStuList()
            } else {
                this.tableData = this.originData.slice(0, 5);
                this.total = this.originData.length
            }
        },
        handleSelectionChange(selection) {
            // console.log('selection---', selection);
        },
        check() {
            this.dialogVisibleCreate = false;
            this.copyBookLogPage();
            this.switchIndex = 2
        },
        // 获取年级列表
        async getGradeList() {
            await this.getUserInfo();
            let resData;
            if (this.role == 11) {
                let data = {
                    schoolId: this.schoolId
                }
                resData = await this.$Api.CopyBook.getGradeIdListBySchoolId(data);
            } else {
                resData = await this.$Api.CopyBook.getGradeList();
            }
            // console.log('年级列表:', resData);
            this.gradeList = resData.data;
            this.downloadFrom.grade = resData.data[0].id;
            this.testForm.grade = resData.data[0].id;
            this.getCellList(resData.data[0].id);
            this.getTestList(resData.data[0].id);
            this.getClassList();
        },
        // 根据年级id获取单元列表
        async getCellList(gradeId) {

            let data = {
                gradeId
            };
            let resData = await this.$Api.CopyBook.getCellList(data);
            this.cellList = resData.data;

            if (this.btnType == 0) {
                this.downloadFrom.cell = -1;
                let cellObj = {
                    name: '全部',
                    id: -1
                };
                this.cellList.unshift(cellObj);
                this.getPeriodList(resData.data[0].id)
            } else {
                this.downloadFrom.cell = resData.data[0].id;
                this.getPeriodList(resData.data[0].id)
            }
        },
        // 根据单元id获取课程列表
        async getPeriodList(unitId) {
            this.downloadFrom.course = -1;
            let data = {
                unitId
            };
            let resData = await this.$Api.CopyBook.getPeriodList(data);
            this.courseList = resData.data;
            let courseObj = {
                name: '全部',
                id: -1
            };
            this.courseList.unshift(courseObj)
        },
        // 根据试卷类型、年级获取试卷列表
        async getTestList() {
            let data = {
                grade: this.testForm.grade,
                type: this.testForm.type
            };
            let resData = await this.$Api.CopyBook.getTestList(data);
            // console.log('试卷列表:', resData);
            this.testList = resData.data;
            this.testForm.testId = resData.data[0].id
        },
        typeChange(val) {
            console.log('val:', val)
            switch (val) {
                case 1:
                    console.log('前测');
                    this.getTestList()
                    break;
                case 2:
                    console.log('后测');
                    this.getTestList()
                    break;
                case 4:
                    console.log('月测');
                    this.getTestList()
                    break;
                case 5:
                    console.log('周测');
                    this.getTestList()
                    break;
                case 11:
                    console.log('同步练字本');
                    this.downloadFrom.cell = -1;
                    this.downloadFrom.course = -1;
                    this.courseList = [
                        {
                            name: '全部',
                            id: -1
                        }
                    ];
                    this.cellList = [
                        {
                            name: '全部',
                            id: -1
                        }
                    ]
                    this.getGradeList();
                    break;
                case 12:
                    console.log('硬笔体系字帖');
                    this.downloadFrom.course = -1;
                    this.downloadFrom.level = '';
                    this.getSysLessonListByGradeId();
                    break;
            }
        },
        gradeChange(gradeId) {
            console.log('选择年级:', gradeId)
            this.getCellList(gradeId);
            this.getTestList(gradeId);
            this.downloadFrom.class = '';
            this.getClassList()
        },
        cellChange(unitId) {
            this.getPeriodList(unitId)
        },
        levelChange(level) {
            // console.log('level:', level)
            this.queryLesson(level)
        },
        // 硬笔体系课字帖查询级别
        async getSysLessonListByGradeId() {
            let data = {
                type: 3
            }
            let resData = await this.$Api.CopyBook.getSysLessonListByGradeId(data);
            this.levelList = resData.data;
            this.downloadFrom.level = resData.data[0].level;
            this.queryLesson(resData.data[0].level)
        },
        // 硬笔体系课根据级别查询课节
        async queryLesson(level) {
            let data = {
                type: 3,
                page: 1,
                limit: 100,
                level
            }
            let resData = await this.$Api.CopyBook.queryLesson(data);
            // console.log('硬笔体系课课节:',resData);
            this.courseList = resData.data;
            if (this.btnType == 0) {
                this.downloadFrom.course = -1;
                let courseObj = {
                    name: '全部',
                    id: -1
                }
                this.courseList.unshift(courseObj);
            } else {
                this.downloadFrom.course = resData.data[0].id;
            }
        },
        // 获取绑定学生选择班级列表
        async getClassList() {
            await this.getUserInfo();
            let resData;
            // this.downloadFrom.grade = '';
            // this.testForm.grade = '';
            if (this.role == 11) {
                let data = {
                    schoolId: this.schoolId,
                    termId: this.switchIndex == 0 ? this.downloadFrom.grade : this.testForm.grade
                }
                resData = await this.$Api.CopyBook.getClassListBySchoolId(data);
                console.log('班级列表11', resData)
                this.classList = resData.data;
                this.classList.unshift({
                    name: '全部',
                    id: -1
                })
            } else {
                resData = await this.$Api.CopyBook.getClassList();
                console.log('班级列表', resData)
                this.classList = resData.data;
            }
        },
        // 全选/全不选学生
        changeAll(val) {
            console.log(val);
            if (val) {
                // 这里注意需要进行深拷贝
                this.bindStuList = JSON.parse(JSON.stringify(this.bindStuListCopy));
                // 添加每个学生的选中状态
                this.originData.map(item => {
                    item.select = true;
                })
            } else {
                this.bindStuList = [];
                // 取消每个学生的选中状态
                this.originData.map(item => {
                    item.select = false;
                })
            }
        },
        // 选中/取消选中学员
        selectStu(stuid, index, status) {
            console.log(stuid, index, status);
            if (!status) {
                // 取消选中
                this.allSelect = false;
                this.bindStuList.splice(index, 1)
            } else {
                // 选中
                this.bindStuList.push(stuid);
                if (this.bindStuList.length == this.originData.length) {
                    this.allSelect = true
                }
            }
        },
        // 获取生成字帖记录
        async copyBookLogPage() {
            let data = {
                size: this.pageSize,
                current: this.page,
                name: this.searchVal
            }
            let resData = await this.$Api.CopyBook.copyBookLogPage(data);
            console.log('获取字帖记录:', resData);
            this.copyList = resData.data.records;
            this.total = resData.data.total;
            let isEnd = resData.data.records.every(item => item.status == 2);
            if (isEnd) {
                clearInterval(this.intervalId);
            } else {
                if (this.intervalId) {
                    console.log('当前有定时器正在执行')
                } else {
                    this.copyBookLogPageInterval()
                }
            }

        },
        // 切换下载教材、生成AI测评字帖
        changeBtnType(type) {
            // 重置字帖类型、级别、年级、单元、课节、绑定学生(同步教材)
            this.btnType = type;
            this.bindStuList = [];
            this.bindStuListCopy = [];
            this.downloadFrom.type = 11;
            this.downloadFrom.level = '';
            this.downloadFrom.grade = '';
            this.downloadFrom.cell = -1;
            this.downloadFrom.course = -1;
            // 重置试卷类型、年级、试卷、绑定学生(测评卷)
            this.testForm.type = 1;
            this.testForm.grade = '';
            this.testForm.testId = '';
            this.testForm.class = '';
            this.getGradeList();
            // this.getClassList();
        },
        // 循环获取字帖生成记录
        copyBookLogPageInterval() {
            let data = {
                size: this.pageSize,
                current: this.page,
                name: this.searchVal
            }
            this.intervalId = setInterval(async () => {
                let resData = await this.$Api.CopyBook.copyBookLogPage(data);
                console.log('获取字帖记录:', resData);
                this.copyList = resData.data.records;
                this.total = resData.data.total;
                let isEnd = resData.data.records.every(item => item.status == 2);
                if (isEnd) {
                    clearInterval(this.intervalId);
                    this.intervalId = null
                }
            }, 10000);
        }
    },
    mounted() {
        this.getUserInfo();
        this.getGradeList();
    },
    beforeDestroy() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
            this.intervalId = null
        }
    }
};
</script>
<style lang='less' scoped>
@import "./less/copybook.less";
</style>